<template>
    <transition-group name="fade" class="c-detail" tag="section">
        <section key="area-intro" class="c-detail-intro">
            <div class="c-detail-intro__content">
                <h1>Uw minimale kavel</h1>
                <h3>Hoeveel oppervlakte kavel moet er beschikbaar zijn?</h3>
            </div>
        </section>

        <section key="area-info" class="c-detail-info">
            <div class="c-detail-info__list">
                <div class="c-detail-text">
                    <h4>Praktische vormgeving.</h4>
                    <p>Lorem Ipsum is slechts een proeftekst uit het drukkerij- en zetterijwezen. Lorem Ipsum is de standaard proeftekst in deze bedrijfstak sinds de 16e eeuw, toen een onbekende drukker een zethaak met letters nam en ze door elkaar husselde om een font-catalogus te maken. Het heeft niet alleen vijf eeuwen overleefd maar is ook, vrijwel onveranderd, overgenomen in elektronische letterzetting. Het is in de jaren ’60 populair geworden met de introductie van Letraset vellen met Lorem Ipsum passages en meer recentelijk door desktop publishing software zoals Aldus PageMaker die versies van Lorem Ipsum bevatten. Lorem Ipsum is slechts een proeftekst uit het drukkerij- en zetterijwezen.</p>
                    <p>en proeftekst uit het drukkerij- en zetterijwezen. Lorem Ipsum is de standaard proeftekst in deze bedrijfstak sinds de 16e eeuw, toen een onbekende drukker een zethaak met letters nam en ze door elkaar husselde om een font-catalogus te maken. Het heeft niet alleen vijf eeuwen overleefd maar is ook,</p>
                    <p>vrijwel onveranderd, overgenomen in elektronische letterzetting. Het is in de jaren ’60 populair geworden met de introductie van Letraset vellen met Lorem Ipsum passages en meer recentelijk door desktop publishing software zoals Aldus PageMaker die versies van Lorem Ipsum bevatten.</p>
                </div>
            </div>
        </section>

        <footer key="area-footer" class="c-detail-footer">
            <router-link to="/sizes" class="o-c2a-link o-c2a-link--tint o-c2a-link--reverse">
                <span class="o-c2a-link__text">Terug naar configuratie</span>
                <span class="o-c2a-link__icon">
                    <span class="o-c2a-link__icon-circle"></span>
                </span>
            </router-link>
        </footer>
    </transition-group>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/essentials";

    .c-detail-intro {
        display: grid;
        grid-template-columns: 10% 1fr 10%;
        padding-top: rem(50);

        @include media-breakpoint-up(sm) {
            padding-top: 7.5%;
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: 20% 1fr 20%;
        }

        &__content {
            grid-column: 2;
        }
    }

    .c-detail-info {
        display: grid;
        grid-template-columns: 10% 1fr 10%;

        @include media-breakpoint-up(lg) {
            grid-template-columns: 20% 1fr 20%;
        }

        &__list {
            border-top: rem(1) solid $gray-light;
            display: grid;
            grid-gap: rem(50) rem(15);
            grid-column: 2;
            padding-bottom: rem(50);
            padding-top: rem(50);
            margin-top: rem(30);

            @include media-breakpoint-up(sm) {
                grid-template-columns: 1fr;
                grid-gap: rem(65) rem(15);
                padding-bottom: 7%;
                padding-top: 7%;
                margin-top: 5%;
            }
        }
    }

    .c-detail-footer {
        @include fixed(left 0 right 0 bottom 0);
        display: grid;
        grid-gap: rem(7);
        grid-template-columns: 1fr;
        border: rem(7) solid $white;
        z-index: 200;
    }
</style>
